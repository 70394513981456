
h3 {
    font-size: 4em;
    margin-top: 65px;
    margin-bottom: 0;
    font-family: "Cedarville Cursive", cursive;
    font-weight: bold;
    font-style: normal; 
}

.project-grid {
    display: grid; 
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 95vw;
    justify-items: center;
    position: relative;
    z-index: 1; 
}

.back {
    height: 10px; 
    margin-right: 5px;
}

.click {
    height: 20px;
    margin-left: 5px;
}

.project-description {
    font-size: 1.4em; 
    margin-top: 0; 
    margin-bottom: 0;
    padding-right: 25px; 
    padding-left: 25px;
    padding-top: 10px;
    line-height: 1.4;
}

#project-container {
    background-color: #007791;
    margin-top: -10px;
    font-family: "Wix Madefor Display", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
}

.project-title {
    display: flex; 
    justify-content: center;
    align-items: center;
}

.project-pic {
    border-radius: 20px;
}

.icon {
    height: 70px;
    margin-left: 15px; 
}

.link, 
.deploy {
    height: 45px; 
    margin-bottom: 15px;
    &:hover {
        transform: scale(1.1);
    }
}

.details {
    background-color: #ebd5d5;
    border-radius: 25px; 
    background-image: url('../../assets/sparkle.png');
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
   width: 600px;
}

.display {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
}

.pine {
    border-radius: 25px;
    width: 100%; 
}

.help, 
.deets {
    display: flex;
    height: 88%;
    justify-content: center;
}

.deets {
    flex-direction: column;
    background-color: #ebd5d5;
    justify-content: flex-start;
    border-radius: 20%; 
}

.deploy {
    margin-right: 10px; 
}

.project {
    border: solid 5px black; 
    cursor: pointer;
}

.cypress {
    height: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 15px; 
}

.social {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react {
    height: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 15px; 
}

.express {
    height: 43px;
    margin-right: 10px; 
    margin-bottom: 10px;
    margin-top: 15px; 
}

.title {
    color: #ebd5d5;;
    font-weight: bold;
    margin-top: 0; 
    margin-bottom: -15px;
}

.progress {
    font-size: 1.75em;
    margin-top: 0;
    font-style: italic;
}

@media screen and (max-width: 1240px) {
    .project-grid { 
        grid-template-columns: repeat(1, 1fr)
}
}

@media screen and (max-width: 767px) {
.details {
    width: 355px;

}

.title {
    font-size: 2em
}

.deets {
    width: 80%
}

.help {
    width: 80%;
    align-items: center; 
}

.project-pic {
    width: 120%;
    height: 70%;
}

.project-description {
    font-size: .95em;
    padding-right: 0; 
    padding-left: 0;
    line-height: 1.3;
}

.link, 
.deploy {
    position: relative; 
    z-index: 1; 
    margin-bottom: -58px; 
    height: 38px; 
}

.social {
    margin-top: 32px; 
}
}