header {
    height: 12vh;
    font-family: "Cedarville Cursive", cursive;
    font-weight: 400;
    font-style: normal; 
    display: flex; 
    background-color: #A9BA9D;
    align-items: center; 
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100vw;
    width: 100%;
    z-index: 1000;
}

h1 {
    font-size: 3em;
    margin-top: 0; 
    margin-bottom: 0;
    margin-left: 50px;
}

a.active {
    color: yellow; 
}

.profession {
    margin-top: -23px; 
    margin-bottom: 0;
    margin-left: 50px; 
    font-size: 1.3em; 
}

.media {
    font-size: 2em;
    font-weight: bold;
    color: black; 
    text-decoration: none;
    margin-right: 40px;
    &:hover {
        text-decoration: underline; 
        color: #007791
    }
}

.cloud {
    height: 20px;
}

.scenic {
    height: 50px;
    margin-left: 35px;
} 

.links {
    display: flex; 
}

.intro {
    display: flex;
    align-items: center; 
}

.role {
    font-size: 1.5em;
}

@media screen and (max-width: 1240px) {
    h1 { 
       font-size: 2em;
}

header {
    overflow-x: hidden;
}

.media {
    font-size: 1.5em; 


}
}
@media screen and (max-width: 1024px) {
    header {
        overflow-x: hidden;
    }

}

@media screen and (max-width: 767px) {

    .media {
        display: none; 
    }

    h1 {
        margin-left: 0; 
    }

    header {
        justify-content: center;
    }
}