.range {
    height: 85px; 
    width: 80%; 
}

footer {
    background-color: #D8BFD8;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-family: "Wix Madefor Display", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
}

.email {
    margin-bottom: 3px; 
    font-size: 1.8em; 
    margin-top: 0;
    color: black;
    text-decoration: underline;
    text-decoration-color: #72A0C1;
    &:hover {
        transform: scale(1.1)
    }
}

.bottom {
    display: flex; 
    width: 33.3%;
    justify-content: center;
    flex-direction: column;
    align-items: center; 
}

h4 {
    font-family: "Cedarville Cursive", cursive;
    font-weight: bold;
    font-style: normal;
    font-size: 3em;
    margin-top: 0;
    margin-bottom: 0;

}

.resume {
    color: black; 
    &:hover {
        transform: scale(1.1)
    }
}

.test {
    width: 33.3%;
    display: flex; 
    flex-direction: column;
    align-items: center;

}

.greeting {
    margin-top: 20px;

}

.pic {
    height: 100px;
    border-radius: 30px;
}

.location {
    margin-top: 0; 
    margin-bottom: 0; 
   
}

.message {
    width: 33%; 


}


.linkedin-small, 
.small {
    height: 40px; 
    &:hover {
        transform: scale(1.1);
    }
}

.github-small {
    margin-right: 5px; 
    height: 36px;   
    &:hover {
        transform: scale(1.1);
    } 
}

.small {
    margin-left: 10px;
}

.linkedin-small {
    margin-left: 5px; 
}

.social-container {
    margin-top: 8px; 
}


@media screen and (max-width: 1200px) {
    .range {
        display: none; 
    }

    .social-container {
        width: 200px;
    }

    footer {
        flex-direction: column;
        width: 100%;
    }

    .message {
        width: 100%
    }

    .pic {
        margin-top: 5px;
    }

}
