.brain {
    width: 100%;
    border-radius: 25px;
}

.details {
    background-color: #ebd5d5;
    height: 400px; 
    border-radius: 25px; 
    margin-bottom: 20px; 
    background-image: url('../../assets/sparkle.png');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.design {
    height: 200px;
    background-color: rgb(235, 213, 213, .8);
    font-size: 1.2em; 
}

.click {
    height: 20px;
    margin-left: 5px;
}