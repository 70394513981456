#bio-container {
    display: flex; 
    height: 130vh; 
}

.mountain {
    height: 117vh;
    width: 35vw;
    background-image: url('https://i.natgeofe.com/n/524a3b15-dd84-4eed-b117-321bb9d65739/lowimpactLEAD.jpg?w=1280&h=1920');
    background-size: cover;
    background-position: center;
    margin-top: 12vh; 
}

.bio {
    background-color: #72A0C1;
    width: 65vw;
    height: 117vh;
    margin-top: 12vh; 

}

.bio-message {
    font-family: "Wix Madefor Display", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    margin-top: 0; 
    width: 90%;
    text-align: center;
    line-height: 1.6;
    margin-bottom: 0; 
    font-size: 1.2em;
}

.photo {
    height: 300px;
    border-radius: 20px;
    margin-top: -240px;
    box-shadow: 10px 5px 5px black;
}

h2 {
    font-family: "Cedarville Cursive", cursive;
    font-weight: bold;
    font-style: normal; 
    font-size: 4.5em; 
    margin-top: 0;
    margin-bottom: 0; 
    color: #734444;
}

.media-container {
    /* margin-right: 5px; */
    display: flex;
    /* justify-content: flex-end; */
    /* margin-top: 10px; */
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    margin-left: 5px;
    margin-top: 20px;
    align-items: center; 
}

.github, 
.linkedin {
    height: 65px;
    &:hover {
        transform: scale(1.1);
    }
}

.github {
    height: 59px;
    margin-bottom: 4px;
}

span {
    color: #aedadd;
    font-weight: bold;
}

.style {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.c {
    color: #aedadd;

}

.italic {
    font-style: italic;
    color: black; 
    font-weight: 500;
}

.alliteration {
    margin-top: 0; 
    font-size: 1.2em;
    width: 90%;
}

@media screen and (max-width: 1240px) {
    .mountain {
        height: 125vh; 
    }

    .bio {
        width: 100%; 
        height: 125vh; 
    }

    #bio-container {
        height: 130vh; 
    }

}

@media screen and (max-width: 1024px) {
    .mountain {
        height: 125vh; 
    }

    .photo {
        height: 230px; 
    }

    h2 {
        font-size: 2.5em; 
    }

    .bio-message {
        font-size: 1em; 
    }

    .alliteration {
        font-size: 1em; 
    }


    .bio {
        width: 100%; 
        height: 125vh; 
    }

    #bio-container {
        height: 130vh; 
    }

}

@media screen and (max-width: 767px) {
    .mountain {
        display: none; 
    }

    .bio {
        width: 100%; 
        height: 110vh; 
    }

    #bio-container {
        height: 120vh; 
    }

    h2 {
        font-size: 3.8em; 
    }

    .github, 
.linkedin {
    height: 55px;
    &:hover {
        transform: scale(1.1);
    }
}

.github {
    height: 49px;
    margin-bottom: 4px;
}

.media-container {
    width: 80px; 
}
}